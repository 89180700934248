<template>
  <div>
    <div v-if="stillLoading" class="loading-page">
      <vue-simple-spinner size="large" message="Fetching Data ..."></vue-simple-spinner>
    </div>
    <div v-else class="min-h-screen">
      <div class="mb-6 flex items-center gap-4">
        <ArrowForward direction="left" @handleClick="() => {
          $router.push('/absensi-report')
        }
          " class="cursor-pointer" />
        <h1 class="text-3xl font-bold">Attendance Report Detail</h1>
      </div>
      <div class="tab flex gap-2 pl-4">
        <div v-for="(tabItem, tabIndex) in tabOptions" :key="`attendance-report-tab` + tabIndex"
          @click="selectTab(tabItem)" class="tab__item text-sm px-5 py-2 rounded-tr-lg rounded-tl-lg cursor-pointer"
          :class="[selectedTab === tabItem ? 'font-bold bg-white' : 'bg-neutral-50']">
          {{ tabItem }}
        </div>
      </div>
      <div class="shadow-small rounded-lg bg-white p-6">
        <StudentDetail :studentData="studentReportDetails" v-if="selectedTab === 'Student Detail'" />
        <Activities v-if="selectedTab === 'Activities'" :studentActivities="studentReportActivityItems" />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { truncate } from '@/utils/string'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'AbsensiReportForm',
  filters: {
    truncate
  },
  data() {
    return {
      selectedTab: 'Student Detail',
      tabOptions: ['Student Detail', 'Activities'],
      stillLoading: true,
      studentReportActivityItems: []
    }
  },
  components: {
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button'),
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField'),
    Search: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Search'),
    Dropdown: () => import(/* webpackChunkName: "Dropdown" */ '@/components/Dropdown/Dropdown'),
    ArrowForward: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/ArrowForward'),
    StudentDetail: () => import('@/views/absensireport/StudentReportDetail.vue'),
    Activities: () => import('@/views/absensireport/StudentReportActivities.vue')
  },
  created() {
    this.getStudentDetails()
  },
  watch: {
    $route(to, from) {
      this.showProgress = true
      this.getStudentDetails()
    }
  },
  computed: {
    ...mapGetters('absensireport', ['studentReportDetails', 'studentReportActivities']),
    clientId() {
      return localStorage.getItem('client')
    },
    studentLmsClassId() {
      return this.$route.params.id
    }
  },
  methods: {
    ...mapActions('absensireport', ['GET_STUDENT_DETAILS', 'GET_STUDENT_ACTIVITIES']),
    selectTab(tab) {
      this.selectedTab = tab
    },
    formatDate(date) {
      return moment(date).local().format('DD MMM YYYY')
    },
    getStudentDetails() {
      this.GET_STUDENT_DETAILS({
        studentLmsClassId: this.studentLmsClassId,
        customerId: this.clientId
      })
        .then(() => {
          this.stillLoading = false
        })
        .catch(() => { })

      this.GET_STUDENT_ACTIVITIES({
        studentLmsClassId: this.studentLmsClassId,
        customerId: this.clientId
      })
        .then(async (res) => {
          if (res?.data?.code === 200) {
            const activity = res?.data?.data
            if (activity.studentLmsClassActivities.length > 0) {
              this.studentReportActivityItems = await this.getStudentActivityItems(activity.studentLmsClassActivities)
            }
          }
        })
        .catch(() => { })
    },

    getStudentActivityItems(studentLmsClassActivities) {
      return new Promise((resolve, reject) => {
        let result = []
        studentLmsClassActivities.map((activity) => {
          if (activity.activityItems.length === 0) {
            result.push(activity)
          } else {
            activity.activityItems.map((activityItems) => {
              let buildActivity = {
                ...activity,
                activityTitle: activity.title
              }
              delete buildActivity.title
              delete buildActivity.activityItems
              result.push({ ...activityItems, ...buildActivity })
            })
          }
        })
        resolve(result)
      })
    }
  }
}
</script>
