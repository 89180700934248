var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.stillLoading ? _c('div', {
    staticClass: "loading-page"
  }, [_c('vue-simple-spinner', {
    attrs: {
      "size": "large",
      "message": "Fetching Data ..."
    }
  })], 1) : _c('div', {
    staticClass: "min-h-screen"
  }, [_c('div', {
    staticClass: "mb-6 flex items-center gap-4"
  }, [_c('ArrowForward', {
    staticClass: "cursor-pointer",
    attrs: {
      "direction": "left"
    },
    on: {
      "handleClick": function handleClick() {
        _vm.$router.push('/absensi-report');
      }
    }
  }), _c('h1', {
    staticClass: "text-3xl font-bold"
  }, [_vm._v("Attendance Report Detail")])], 1), _c('div', {
    staticClass: "tab flex gap-2 pl-4"
  }, _vm._l(_vm.tabOptions, function (tabItem, tabIndex) {
    return _c('div', {
      key: "attendance-report-tab" + tabIndex,
      staticClass: "tab__item text-sm px-5 py-2 rounded-tr-lg rounded-tl-lg cursor-pointer",
      class: [_vm.selectedTab === tabItem ? 'font-bold bg-white' : 'bg-neutral-50'],
      on: {
        "click": function click($event) {
          return _vm.selectTab(tabItem);
        }
      }
    }, [_vm._v(" " + _vm._s(tabItem) + " ")]);
  }), 0), _c('div', {
    staticClass: "shadow-small rounded-lg bg-white p-6"
  }, [_vm.selectedTab === 'Student Detail' ? _c('StudentDetail', {
    attrs: {
      "studentData": _vm.studentReportDetails
    }
  }) : _vm._e(), _vm.selectedTab === 'Activities' ? _c('Activities', {
    attrs: {
      "studentActivities": _vm.studentReportActivityItems
    }
  }) : _vm._e()], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }